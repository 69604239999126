module controllers {
    export module sales {
        interface ISalesCreateScope extends ng.IScope {
        }

        enum EnumCreate {
            CreateNew = 0,
            FromExisting = 1
        }

        export class salesOrderCreateCtrl {
            static $inject = ["$scope", '$rootScope', 'generalService', '$q', 'entityService', 'bsLoadingOverlayService', 'salesOrderTypeService',
                'supplierService', 'counterService', "$state", "salesOrderService", "consigneeService",'customerService', "CreateFromExisting"];

            createFrom: EnumCreate;

            entityList: ng.resource.IResourceArray<interfaces.applicationcore.IEntity>;
            ownerEntityId: number;

            salesOrderTypeList: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>;
            salesOrderTypeDefer: ng.IDeferred<any>;
            TypeId: number;

            loadPromises: ng.IPromise<any>[];

            supplierBranch: interfaces.applicationcore.IDropdownModel;
            supplierList: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>;

            customerBranch: interfaces.applicationcore.IDropdownModel;
            customerList: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>;

            consigneeBranch: interfaces.applicationcore.IDropdownModel;
            consigneeList: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>;

            SOCounterId: number;
            counterTypeDefer: ng.IDeferred<any>;
            SOCounterList: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>;

            customNumberCheck: boolean = false;
            customNumber: string;

            soId: Number = 0;

            selectedSO: interfaces.applicationcore.IDropdownModel;
            selectedSalesOrderType: interfaces.sales.ISalesOrderType;

            poList: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>;

            constructor(private $scope: ISalesCreateScope, private $rootScope: interfaces.applicationcore.IRootScope, public generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService, private entityService: interfaces.applicationcore.IEntityService, private bsLoadingOverlayService,
                private salesOrderTypeService: interfaces.sales.ISalesOrderTypeService, private supplierService: interfaces.master.ISupplierService,
                private counterService: interfaces.master.ICounterService, private $state: ng.ui.IStateService,
                private salesOrderService: interfaces.sales.ISalesOrderService, public consigneeService: interfaces.master.IConsigneeService, public customerService: interfaces.master.ICustomerService,
                private CreateFromExisting: boolean) {

                this.createFrom = EnumCreate.CreateNew;

                this.loadPromises = [];
                this.ownerEntityId = $rootScope.Entity.Id;

                this.loadPromises.push(this.loadEntities());
                this.loadPromises.push(this.getCurrentEntity());

                this.salesOrderTypeDefer = $q.defer();
                this.loadPromises.push(this.salesOrderTypeDefer.promise);

                this.counterTypeDefer = $q.defer();
                this.loadPromises.push(this.counterTypeDefer.promise);

                bsLoadingOverlayService.wrap({
                    referenceId: 'create'
                }, () => {
                    return $q.all(this.loadPromises);
                });

            }

            loadConsignees(searchText: string) {
                var defered = this.$q.defer();

                if (!this.ownerEntityId) {
                    defered.resolve([]);
                    return defered.promise;
                }

                this.consigneeService.getDropdownList(this.ownerEntityId, searchText).query({}, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                    defered.resolve(list);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    defered.resolve([]);
                });

                return defered.promise;
            } 

            loadCustomers(searchText: string) {
                var defered = this.$q.defer();

                if (!this.ownerEntityId) {
                    defered.resolve([]);
                    return defered.promise;
                }

                this.customerService.getDropdownList(this.ownerEntityId, searchText).query({}, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                    defered.resolve(list);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    defered.resolve([]);
                });

                return defered.promise;
            }



            loadEntities() {
                return this.entityService.getList().query((successList) => {
                    this.entityList = successList;
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            getCurrentEntity() {
                return this.entityService.getCurrentEntity().query((result: interfaces.applicationcore.IEntity) => {
                    this.ownerEntityId = result.Id;
                    this.loadSalesOrderTypes();
                    this.loadSOCounter();
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }



            ownerEntityChanged() {
                this.supplierBranch = undefined;
                this.TypeId = undefined;
                this.SOCounterId = undefined;
                this.selectedSO = undefined;

                this.supplierList = undefined;
                this.customerList = undefined;
                this.consigneeList = undefined;
                this.SOCounterList = undefined;
                this.poList = undefined;

                this.salesOrderTypeDefer = this.$q.defer();
                this.loadSalesOrderTypes();

                this.counterTypeDefer = this.$q.defer();
                this.loadSOCounter();

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'create'
                }, () => {
                    return this.salesOrderTypeDefer.promise;
                });
            }

            salesOrderTypeChanged() {
                this.salesOrderTypeService.getSalesOrderType().query({ sotId: this.TypeId }, (result: interfaces.sales.ISalesOrderType) => {
                    this.selectedSalesOrderType = result;
                    if (this.selectedSalesOrderType)
                    {
                        this.SOCounterId = this.selectedSalesOrderType.CounterId;
                        if (result.Supplier)
                        {
                            this.supplierBranch = result.Supplier;
                        }
                    }
                });
            }

            loadSalesOrderTypes() {
                this.salesOrderTypeService.getDropdownList().query({ ownerEntityId: this.ownerEntityId }, (result: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                    if (this.salesOrderTypeDefer) {
                        this.salesOrderTypeDefer.resolve();
                    }

                    this.salesOrderTypeList = result;

                    angular.forEach(this.salesOrderTypeList, (o) => {
                        if (o.Selected) {
                            this.TypeId = o.Id;
                            this.salesOrderTypeChanged();
                        }
                    });

                }, (failureData) => {
                    if (this.salesOrderTypeDefer) {
                        this.salesOrderTypeDefer.reject();
                    }
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    this.salesOrderTypeList = <ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>>[];
                });
            }

            loadSuppliers(searchText: string) {
                if (this.ownerEntityId) {
                    return this.supplierService.getSupplierDropdown(this.ownerEntityId, searchText).query(() => {

                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }

            loadSOCounter() {
                this.counterService.getDropdownList().query({
                    ownerEntityId: this.ownerEntityId,
                    counterTypeId: Enum.EnumCounterTypes.SalesOrder
                }, (result: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {

                    this.SOCounterList = result;

                    if (this.counterTypeDefer) {
                        this.counterTypeDefer.resolve();
                    }

                    angular.forEach(result, (o) => {
                        if (o.Selected) {
                            this.SOCounterId = o.Id;
                        }
                    });

                }, (failureData) => {

                    if (this.counterTypeDefer) {
                        this.counterTypeDefer.reject();
                    }

                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            loadSalesOrders(searchText: string) {
                return this.salesOrderService.getDropdownList().query({
                    OwnerEntityId: this.ownerEntityId,
                    searchText: searchText
                }, () => {

                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            customCounterChanged() {
                this.customNumber = '';
            }

            public selectedSOChanged(model: interfaces.applicationcore.IDropdownModel) {
                if (model) {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'create'
                    }, () => {
                        return this.salesOrderService.getSalesOrder().query({
                            Id: model.Id
                        }, (result: interfaces.sales.ISalesOrder) => {
                            this.supplierBranch = result.Supplier;
                            this.customerBranch = result.Customer;
                            this.consigneeBranch = result.Consignee;
                        }, (failureData) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        });
                    });
                }

            }

            consigneeChanged(model: interfaces.applicationcore.IDropdownModel) {

                //Always try and get Route default from Consignee
                this.loadConsigneeDefaults(model.Id);
            }

            loadConsigneeDefaults(consigneeId: number) {
                return this.consigneeService.getConsigneeDefaults(consigneeId).get({

                }, (data: interfaces.master.IConsigneeDefaults) => {
                    if (data.Customer) {
                        this.customerBranch = data.Customer;
                    }
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            close() {
                this.$state.go("^");
            }

            create() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'create'
                }, () => {
                    return this.createSO().then((result) => {
                        this.generalService.displayMessageHandler(result);
                        this.soId = Number(result.ID);
                        this.$state.go("auth.SalesOrders.Update", { soId: this.soId });
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    });
                });


            }

            createSO() {
                this.customNumber = this.customNumber || '';
                return this.salesOrderService.create().save({
                    OwnerEntityId: this.ownerEntityId,
                    TypeId: this.TypeId,
                    SupplierBranchId: this.supplierBranch.Id,
                    CustomerBranchId: this.customerBranch.Id,
                    ConsigneeBranchId: this.consigneeBranch.Id,
                    SOCounterId: !this.customNumberCheck ? this.SOCounterId : null,
                    CustomNumber: this.customNumber,
                    soId: this.selectedSO ? this.selectedSO.Id : undefined
                }).$promise;
            }
        }

        angular.module("app").controller("salesOrderCreateCtrl", controllers.sales.salesOrderCreateCtrl);
    }
}